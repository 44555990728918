<app-setup-guide-initial-dialog
    *ngIf="setupGuide.isPersisted"
    [setupGuide]="setupGuide"
></app-setup-guide-initial-dialog>

<div class="side-nav-container">
    <div class="heading-container">
        <div class="flex margin-bottom-2 space-between">
            <h2 class="margin-bottom-0">{{ 'time-off-v3.time-off-setup-guide.heading' | translate }}</h2>

            <ui-button
                type="icon"
                [size]="'no-padding'"
                (click)="rightSideNav.close(); rightSideNav.persistClose(); trackSetupGuideClosed()"
                class="cancel"
            >
                <div class="sr-only">{{ 'close' | translate }}</div>
                <mat-icon svgIcon="cancel"></mat-icon
            ></ui-button>
        </div>
        <ng-container *ngIf="!showFinalAction">
            <div class="flex margin-bottom-3 sub-heading">
                {{ 'time-off-v3.time-off-setup-guide.subHeading' | translate }}
            </div>

            <div class="flex margin-bottom-3">
                <mat-progress-bar
                    class="green-fill"
                    mode="determinate"
                    [value]="(stepsCompleted / totalSteps) * 100"
                ></mat-progress-bar>
                <div class="subtext">
                    {{
                        'time-off-v3.time-off-setup-guide.completedSteps'
                            | translate: { completed: stepsCompleted, totalSteps: totalSteps }
                    }}
                </div>
            </div>
        </ng-container>

        <ui-card *ngIf="showFinalAction" class="final-action-card margin-bottom-1" variant="outline">
            <div>
                <img src="/assets/illustrations/High-Five-1-small.png" alt="Two hands hive five" />
            </div>
            <h3 class="margin-top-2">
                {{ 'time-off-v3.time-off-setup-guide.wellDoneYouveMasteredTimeOff' | translate }}
            </h3>
            <p>{{ 'time-off-v3.time-off-setup-guide.noteThatTheseDefaultPolicies' | translate }}</p>
            <p>{{ 'time-off-v3.time-off-setup-guide.nowGetStarted' | translate }}</p>

            <ui-button [type]="'link'" [size]="'stripped'" (click)="completeSetupGuide()">
                {{ 'time-off-v3.time-off-setup-guide.dontShowThisGuideAnymore' | translate }}
            </ui-button>
        </ui-card>
    </div>

    <mat-accordion role="list">
        <mat-expansion-panel
            *ngFor="let setupGuideStep of setupGuideSteps; let i = index"
            [ngClass]="{ complete: setupGuideStep.completedAt }"
            hideToggle
            class="expansion-panel sidebar-expansion-panel"
            [expanded]="setupGuideStepMeta[setupGuideStep.stepKey].expanded"
            (opened)="setupGuideStepMeta[setupGuideStep.stepKey].expanded = true"
            (closed)="setupGuideStepMeta[setupGuideStep.stepKey].expanded = false"
            [@.disabled]="disableAnimations"
            role="listitem"
            [attr.aria-labelledby]="'step-title-' + i"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="bullet circle">
                        <span
                            [ngClass]="{
                                'some-progress': setupGuideStepMeta[setupGuideStep.stepKey].expanded,
                            }"
                            >{{ i + 1 }}</span
                        >
                        <mat-progress-spinner
                            class="green-fill"
                            mode="determinate"
                            [value]="setupGuideStepMeta[setupGuideStep.stepKey].expanded ? 100 : 0"
                            diameter="28"
                            strokeWidth="2"
                        ></mat-progress-spinner>
                        <mat-icon svgIcon="check" class="checkmark"></mat-icon>
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <p class="title" [id]="'step-title-' + i">
                        {{ setupGuideStepMeta[setupGuideStep.stepKey].name | translate }}
                    </p>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="panel-content" *ngIf="!stepOpenedState[setupGuideStep.stepKey]">
                <mat-action-row>
                    <ui-button type="primary" (click)="openStep(setupGuideStep.stepKey)">{{
                        (setupGuideStep.completedAt
                            ? setupGuideStepMeta[setupGuideStep.stepKey].buttonTextCompleted
                            : setupGuideStepMeta[setupGuideStep.stepKey].buttonText
                        ) | translate
                    }}</ui-button>
                </mat-action-row>
            </div>

            <div *ngIf="stepOpenedState[setupGuideStep.stepKey]" class="panel-accordion-content">
                @if (setupGuideStepMeta[setupGuideStep.stepKey].nestedDescription) {
                    <p
                        class="nested-description"
                        [innerHTML]="
                            setupGuideStepMeta[setupGuideStep.stepKey].nestedDescription ?? ''
                                | translate
                                | sanitizeHtml
                        "
                    ></p>
                }

                <mat-checkbox
                    class="label-top"
                    *ngIf="setupGuideStepMeta[setupGuideStep.stepKey].checkboxConsentText"
                    [(ngModel)]="setupGuideStepMeta[setupGuideStep.stepKey].checkboxEnableNestedButton"
                >
                    <span>{{ setupGuideStepMeta[setupGuideStep.stepKey].checkboxConsentText | appTranslate }}</span>
                </mat-checkbox>

                <mat-accordion class="nested-accordion">
                    <mat-expansion-panel
                        *ngFor="let nestedStep of setupGuideStepMeta[setupGuideStep.stepKey].nestedSteps"
                        [hideToggle]="false"
                    >
                        <mat-expansion-panel-header>
                            <mat-panel-title class="nested-title"> {{ nestedStep.name | translate }} </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div
                            class="nested-description-copy"
                            [innerHTML]="nestedStep.description | translate | sanitizeHtml"
                        ></div>

                        <div
                            class="margin-top-1"
                            [innerHTML]="
                                'time-off-v3.time-off-setup-guide.learnMoreLink'
                                    | translate
                                        : {
                                              url: nestedStep.learnMoreLink | i18nHelpCentre,
                                              text: nestedStep.learnMoreLinkText | translate,
                                          }
                            "
                        ></div>
                    </mat-expansion-panel>
                </mat-accordion>
                <!-- Show button disabled and linked with consent -->
                <ui-button
                    *ngIf="setupGuideStepMeta[setupGuideStep.stepKey].checkboxConsentText"
                    [disabled]="
                        !setupGuideStepMeta[setupGuideStep.stepKey].checkboxEnableNestedButton ||
                        setupGuideStep.completedAt
                    "
                    size="full-width"
                    (click)="completeStep(setupGuideStep)"
                    type="primary"
                    >{{ setupGuideStepMeta[setupGuideStep.stepKey].nestedButtonText | translate }}</ui-button
                >
                <ui-button
                    [disabled]="setupGuideStep.completedAt"
                    *ngIf="!setupGuideStepMeta[setupGuideStep.stepKey].checkboxConsentText"
                    size="full-width"
                    type="primary"
                    (click)="completeStep(setupGuideStep)"
                    >{{ setupGuideStepMeta[setupGuideStep.stepKey].nestedButtonText | translate }}</ui-button
                >
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="margin-left-2">
        <p class="margin-bottom-1">
            <span>{{ 'time-off-v3.time-off-setup-guide.learnMoreAboutTimeOffPrefix' | translate }}</span>
            <a
                (click)="trackLearnMoreAboutTimeOff()"
                href="{{ '4409791222295' | i18nHelpCentre }}"
                target="_blank"
                rel="noopener noreferrer"
                >{{ 'time-off-v3.time-off-setup-guide.learnMoreAboutTimeOffLink' | translate }}</a
            >
        </p>
        <p>
            <span>{{ 'time-off-v3.time-off-setup-guide.contactUsPrefix' | translate }}</span>
            <a
                (click)="trackContactUs()"
                href="{{ 'new' | i18nHelpCentre }}"
                target="_blank"
                rel="noopener noreferrer"
                >{{ 'time-off-v3.time-off-setup-guide.contactUsLink' | translate }}</a
            >
        </p>
    </div>
</div>
